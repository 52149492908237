<template>
  <div class="fragment">
    <div class="dashboard-row">
<!--      <div class="dashboard-col">-->
<!--        <CalculateShipping-->
<!--            :list="list"-->
<!--        />-->
<!--        &lt;!&ndash;            @changeInfoCardsList="changeInfoCardsList"&ndash;&gt;-->


<!--      </div>-->
      <div class="dashboard-col">
        <CalculateShipping
            :list="draggableList"
            @changeInfoCardsList="changeInfoCardsList"
        />
      </div>
      <div class="dashboard-col"
           v-if="withoutTrackingTransactions && Object.keys(withoutTrackingTransactions).length > 0">
<!--        <DashboardWarning-->
<!--            :withoutTrackingTransactions="withoutTrackingTransactions"-->
<!--        />-->
      </div>
      <div class="dashboard-col" v-if="reconnectShops && reconnectShops.length > 0">
        <DashboardReconnectShopsWarning
            :reconnectShops="reconnectShops"
            @removeReconnectShop="data => removeReconnectShop(data)"
        />
      </div>

      <div class="dashboard-col" v-if="$store.getters.getUserProfile.auth_type !== 'google'">
        <DashboardGoogleAuthWarning/>
      </div>
      <div class="dashboard-col dashboard-col--25 dashboard-col--lg-50 dashboard-col--md-100"
           v-if="_.has(totalInfo, 'atm') && totalInfo.atm.length > 0">
        <div class="dashboard-info-block">
          <div class="dashboard-info-block__inner">
            <div class="dashboard-info-block__row">
              <div class="dashboard-info-block__img">
                <img src="/img/UI-group/atm-icon.svg" alt="ico">
              </div>
              <div class="dashboard-info-block__content"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'dashboard_atm',
                  'dashboard_maxAtOneTime',
                  'dashboard_maxPerWeek',
                  'dashboard_maxPerDay',
                  'dashboard_maxPerOfFunds'
                    ])"></span>
                <div class="dashboard-info-block__title">
                  {{ $t('dashboard_atm.localization_value.value') }}
                  <!--                  ATM-->
                </div>
                <p>
                  {{ $t('dashboard_maxAtOneTime.localization_value.value') }}
                  <!--                  Maximum withdraw amount at one time:-->
                  <ValueHelper
                      :value="totalInfo['atm']['atm_limit_for_one_time']"
                  />
                </p>
                <p>
                  {{ $t('dashboard_maxPerWeek.localization_value.value') }}
                  <!--                  Maximum withdraws per week:-->
                  <!--                  <ValueHelper-->
                  <!--                      :value="totalInfo['atm']['atm_limit_for_week']['limit']"-->
                  <!--                  />-->
                  <!--{{totalInfo['atm']['atm_limit_for_week']['output_sum']}}-->
                  <!--({{totalInfo['atm']['atm_limit_for_week']['limit']}})-->
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_for_week.output_sum'"
                  />
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_for_week.limit'"
                  />
                </p>
                <p>
                  {{ $t('dashboard_maxPerDay.localization_value.value') }}
                  <!--                  Maximum withdraws per day:-->
                  <!--                  <ValueHelper-->
                  <!--                      :value="totalInfo['atm']['atm_limit_for_per_day']['limit']"-->
                  <!--                  />-->
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_for_per_day.output_sum'"
                  />
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_for_per_day.limit'"
                  />
                  <!--{{totalInfo['atm']['atm_limit_for_per_day']['output_sum']}}-->
                  <!--({{totalInfo['atm']['atm_limit_for_per_day']['limit']}})-->
                </p>
                <p>
                  {{ $t('dashboard_maxPerOfFunds.localization_value.value') }}
                  <!--                  Maximum % of funds:-->
                  <!--                  <ValueHelper-->
                  <!--                      :value="totalInfo['atm']['atm_limit_max_percent']['limit']"-->
                  <!--                  />-->
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_max_percent.output_sum'"
                  />
                  <ValueHelper
                      :value="totalInfo"
                      :deep="'atm.atm_limit_max_percent.limit'"
                  />
                  <!--{{totalInfo['atm']['atm_limit_max_percent']['output_sum']}}-->
                  <!--({{totalInfo['atm']['atm_limit_max_percent']['limit']}})-->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-col mb-0"></div>
      <div class="dashboard-col dashboard-col--25 dashboard-col--lg-50 dashboard-col--md-100">
        <PaymentBlock/>
      </div>
      <div class="dashboard-col dashboard-col--75 dashboard-col--lg-100 order-mobile-last"
           v-if="$store.getters.getNotificationsNoRead !== 'undefined' && $store.getters.getNotificationsNoRead.length > 0"
      >
        <Notifications/>
<!--        <GraphUsualOrders/>-->
      </div>
<!--      <div class="dashboard-col dashboard-col&#45;&#45;20 dashboard-col&#45;&#45;lg-50 dashboard-col&#45;&#45;md-100">-->
<!--        <QuickMoneyBlock/>-->
<!--      </div>-->
<!--      <div class="dashboard-col"-->
<!--           v-if="$store.getters.getNotificationsNoRead !== 'undefined' && $store.getters.getNotificationsNoRead.length > 0"-->
<!--      >-->
<!--        <Notifications/>-->
<!--      </div>-->
<!--      <div class="dashboard-col order-mobile-last"-->
<!--      >-->
<!--        <GraphFinanceStatistics/>-->
<!--      </div>-->
    </div>

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="changeThankYouPopupPopup(false)"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments"
    />
    <ImportEtsyListings
        v-if="importEtsyListings"
        @close="importEtsyListings = false"
    />

    <CreateRAMPPopup
        v-if="createRAMPPopup"
        @close="createRAMPPopup = false"
    />

<!--    <CreatePayoneerCheckPopup-->
<!--        v-if="createPayoneerCheckPopup"-->
<!--        @close="createPayoneerCheckPopup = false"-->
<!--    />-->

  </div>
</template>

<script>
import CalculateShipping from "../CalculateShipping/CalculateShipping.vue";
// import GraphUsualOrders from "../GraphUsualOrders/GraphUsualOrders.vue";
import PaymentBlock from "../PaymentBlock/PaymentBlock.vue";
// import QuickMoneyBlock from "../QuickMoneyBlock/QuickMoneyBlock.vue";
import Notifications from "../Notifications/Notifications.vue";
// import GraphFinanceStatistics from "../GraphFinanceStatistics/GraphFinanceStatistics.vue";
import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup.vue";
import ImportEtsyListings from "../../popups/ImportEtsyListings/ImportEtsyListings";
// import DashboardWarning from "../DashboardWarning/DashboardWarning";
import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
import DashboardGoogleAuthWarning from "../DashboardGoogleAuthWarning/DashboardGoogleAuthWarning";
import {
  DASHBOARD_DRAGGABLE,
  PAYPAL_CASES_STATUS_OPENED,
  RETURN_GOODS_TYPES
} from "../../../../../staticData/staticVariables";
import DashboardReconnectShopsWarning
  from "@/components/modules/DashboardModule/components/DashboardReconnectShopsWarning/DashboardReconnectShopsWarning";
import {isArray} from "chart.js/helpers";
import CreateRAMPPopup from "@/components/coreComponents/Popups/CreateRAMPPopup/CreateRAMPPopup";
// import CreatePayoneerCheckPopup
//   from "@/components/coreComponents/Popups/CreatePayoneerCheckPopup/CreatePayoneerCheckPopup";

export default {
  name: "DashboardUser",
  components: {
    // CreatePayoneerCheckPopup,
    CreateRAMPPopup,
    DashboardReconnectShopsWarning,
    DashboardGoogleAuthWarning,
    ValueHelper,
    // DashboardWarning,
    ImportEtsyListings,
    CalculateShipping,
    // GraphUsualOrders,
    PaymentBlock,
    // QuickMoneyBlock,
    Notifications,
    // GraphFinanceStatistics,
    ThankYouPopup,
  },

  watch: {
    // frozenBalance(newVal) {
    //   this.list[1].bigText2 = '$' + newVal
    //   // else {
    //   //   this.list[1].bigText2 = false
    //   // }
    // },

    // loadUserRoleAndAuthorizedData(newVal) {
    //   if (newVal) {
    //     this.setUserBalance()
    //     this.setUserPaymentListings()
    //   }
    // },
  },

  // computed: {
  // frozenBalance: function () {
  //   return this.$store.getters.GET_COMMON_AUTHORIZED?.user?.frozen_balance
  // },
  // },
  data() {
    return {
      importEtsyListings: false,
      thankYouPopupPopup: false,
      createRAMPPopup: false,
      createPayoneerCheckPopup: false,
      list: [],
      draggableList: [
        // {
        //   firstElement: true,
        //   firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
        // },
      ],
      userBalance: 0,

      withoutTrackingTransactions: [],
      reconnectShops: [],
      totalInfo: {},
    }
  },

  created() {
    // this.list = [
    //   {
    //     firstElement: true,
    //     firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
    //   },
    //
    //   // {
    //   //   ico: '/img/dashboard-group/balance-icon.svg',
    //   //   bigText: '$' + this.$store.getters.GET_COMMON_AUTHORIZED?.user?.frozen_balance,
    //   //   textFromLink: 'On hold',
    //   //   textFromLinkHref: this.$store.getters.GET_PATHS.forbiddenGoods,
    //   //   // text: 'My Balance',
    //   // },
    //   {
    //     ico: '/img/dashboard-group/returned-packages-icon.svg',
    //     bigText: '$200',
    //     text: 'Returned Packages',
    //   },
    //   {
    //     ico: '/img/dashboard-group/unidentified-packages-icon.svg',
    //     bigText: '20',
    //     text: 'Unidentified Packages',
    //   },
    //   {
    //     ico: '/img/dashboard-group/invoices-icon.svg',
    //     bigText: '$594',
    //     text: 'Invoices',
    //   },
    //   {
    //     ico: '/img/dashboard-group/shops-icon.svg',
    //     bigText: '5',
    //     text: 'Shops',
    //     linkText: 'Connect New',
    //     linkPath: ''
    //   },
    //
    //
    //   {
    //     ico: '/img/dashboard-group/ship-to-amazon-icon.png',
    //     bigText: '10',
    //     text: 'Ship to Amazon',
    //   },
    //   {
    //     ico: '/img/dashboard-group/fba-invoices-icon.svg',
    //     bigText: '54',
    //     text: 'FBA Invoices',
    //   },
    //   {
    //     ico: '/img/dashboard-group/paypal-transactions-icon.png',
    //     bigText: '$340',
    //     text: 'PayPal Transactions',
    //   },
    // ]
  },

  mounted() {

    this.$store.dispatch('getDashboardDraggableBlockData').then(response => {

      let draggableData = this.getRespData(response)

      if(!isArray(draggableData)) return

      draggableData.map(draggableItem => {
        switch (draggableItem.name) {
          case DASHBOARD_DRAGGABLE.BALANCE:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.PAYMENT_HISTORY_GENERAL)) return

              //fix to make balances the same if some changes happened in between user getting authorized-config
            if (draggableItem.values?.balance && (+this.$store.getters.getUserProfile.balance !== +draggableItem.values?.balance)) {
              let user = this.$store.getters.getUserProfile
              user.balance = parseFloat(draggableItem.values?.balance).toFixed(2)
              this.$store.commit('setUserProfile', {userProfile: user})
            }
            this.draggableList.push({
              ico: '/img/dashboard-group/balance-icon.svg',
              bigText: '$' + draggableItem.values?.balance,
              textFromLink: this.$t('dashboard_myBalance.localization_value.value'),
              textFromLinkTranslate: 'dashboard_myBalance',
              textFromLinkHref: this.$store.getters.GET_PATHS.balanceHistory,

              bigText2: '$' + draggableItem.values?.frozen_balance,
              textFromLink2: this.$t('dashboard_OnHold.localization_value.value'),
              textFromLinkHref2: this.$store.getters.GET_PATHS.financePayPalCases + `?page=1&count=25&status=${PAYPAL_CASES_STATUS_OPENED.value}`,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.PAYONEER_BALANCE:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER)) return

            this.draggableList.push({

              ico: '/img/dashboard-group/payoneer-logo.png',
              icoStyle: 'max-width: 40px;',
              bigText: '$' + (draggableItem.values?.balance ? draggableItem.values?.balance : 0),
              textFromLink: this.$t('dashboard_balancePayoneer.localization_value.value'),
              textFromLinkTranslate: 'dashboard_balancePayoneer',
              textFromLinkHref: this.$store.getters.GET_PATHS.balancePayoneerHistory,

              // bigText2: '$',
              // textFromLink2: this.$t('dashboard_sendCheck.localization_value.value'),
              // textFromLinkFunc2: this.toggleCreatePayoneerCheckPopup,

              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCELS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return
            this.draggableList.push({
              ico: '/img/dashboard-group/returned-packages-icon.svg',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_myReturns.localization_value.value'),
              linkTextTranslate: 'dashboard_myReturns',
              linkPath: this.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.MY_RETURNS,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.RETURN_PARCEL_UNDEFINED:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.RETURN_PARCEL_GENERAL)) return
            this.draggableList.push({
              ico: '/img/dashboard-group/unidentified-packages-icon.svg',
              bigText: draggableItem.values?.count,
              linkText: this.$t('dashboard_unidentifiedPackages.localization_value.value'),
              linkTextTranslate: 'dashboard_unidentifiedPackages',
              linkPath: this.$store.getters.GET_PATHS.problemsUnidentifiedReturns,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.INVOICES:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/invoices-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('dashboard_Invoices.localization_value.value'),
              linkTextTranslate: 'dashboard_Invoices',
              linkPath: this.$store.getters.GET_PATHS.financeInvoices,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.SHOPS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.SHOPS_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/shops-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              text: this.$t('dashboard_Shops.localization_value.value'),
              textTranslate: 'dashboard_Shops',
              linkText: this.$t('dashboard_connectNew.localization_value.value'),
              linkTextTranslate: 'dashboard_connectNew',
              linkPath: this.$store.getters.GET_PATHS.mainSettingsShopsCreate,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          // case DASHBOARD_DRAGGABLE.LISTINGS:
          //   if((!this.isSubUser && !this.getUserUsePaymentListing) || (this.isSubUser && !this._.has(this.currentPermissions, this.PERMISSIONS.PAYMENT_LISTING_GENERAL))){
          //     return
          //   }
          //
          //   this.draggableList.push({
          //     ico: '/img/dashboard-group/listings-icon.svg',
          //     links: [
          //       // {
          //       //   text: this.$t('dashboard_listings.localization_value.value'),
          //       //   textTranslate: 'dashboard_listings',
          //       //   linkText: this.$t('dashboard_createNew.localization_value.value'),
          //       //   linkTextTranslate: 'dashboard_createNew',
          //       //   linkPath: this.$store.getters.GET_PATHS.financeUkrTreasuresLinkCreate
          //       // },
          //       {
          //         text: this.$t('dashboard_Etsy.localization_value.value'),
          //         textTranslate: 'dashboard_Etsy',
          //         linkText: this.$t('dashboard_CreateEtsy.localization_value.value'),
          //         linkTextTranslate: 'dashboard_CreateEtsy',
          //         useFunction: this.getEtsyListing
          //       },
          //     ],
          //     sort: draggableItem.sort,
          //     dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
          //   })
          //   break
          case DASHBOARD_DRAGGABLE.SHIP_AMAZON:
            this.draggableList.push({
              ico: '/img/dashboard-group/ship-to-amazon-icon.png',
              bigText: '-',
              text: this.$t('dashboard_shipToAmazon.localization_value.value'),
              textTranslate: 'dashboard_shipToAmazon',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.FBA_INVOICES:
            this.draggableList.push({
              ico: '/img/dashboard-group/fba-invoices-icon.svg',
              bigText: '-',
              text: this.$t('dashboard_fBAInvoices.localization_value.value'),
              textTranslate: 'dashboard_fBAInvoices',
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.PAYPAL_TRANSACTIONS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_GENERAL)) return

            this.draggableList.push({
              ico: '/img/dashboard-group/paypal-transactions-icon.png',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('dashboard_payPalTransactions.localization_value.value'),
              linkTextTranslate: 'dashboard_payPalTransactions',
              linkPath: this.$store.getters.GET_PATHS.cusPayments,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.CALCULATE_SHIPPING:
            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.calculator,
              firstElementText: this.$t('dashboard_calculateShipping.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.CALCULATE_ENGRAVING_SHIPPING:
            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.calculatorEngraving,
              firstElementText: this.$t('dashboard_calculateEngravingShipping.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.DELIVERY_STATISTIC:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.DELIVERY_STATISTIC_GENERAL)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.statisticsDeliveryStatistics,
              firstElementText: this.$t('dashboard_deliveryStatistics.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.SENT_SMS:
            if (!this._.has(this.currentPermissions, this.PERMISSIONS.SENT_SMS_GENERAL)) return

            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.chat,
              firstElementText: this.$t('dashboard_smsChat.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          case DASHBOARD_DRAGGABLE.HEARTY_FROZEN_TRANSACTIONS:
            if (!this.isUserHeartySeller) return

            this.draggableList.push({
              ico: '/img/dashboard-group/invoices-icon.svg',
              bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
              linkText: this.$t('dashboard_heartysanFrozen.localization_value.value'),
              linkTextTranslate: 'dashboard_heartysanFrozen',
              linkPath: this.$store.getters.GET_PATHS.cusPayments,
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break

          case DASHBOARD_DRAGGABLE.RAMP:
            this.draggableList.push({
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
              ico: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMiSURBVHgBvVdLTxNRFD7nTLswoU3ZibKYGhF3lER3JoA746Ymxp0h4IrEBy6NJsKCjSuIiaxQ/Ae4cacIcacJuOMR6SQ+cGe1TUwsc6/n3KHt0NdM7ZQvIW3vvdzvO98958wdhJDIaTsVL0AWiUa01hnQaPNw6nA6D6gdRNp0Fay5CXcljU4+zL4YtCD3x7ZjBzSNGsZ9hMEbEy6T5c72nXCcluuaTZiIi/QYNExDJyCY7+/Zuw/tCJCo4yVa5a82RAPHiquxRm5Q7cDX4kAmYnKB7fKe+xxY7cQRB7oQeS3qnKgI8LKcNrpIXhFRctVwuterksoRmITrkHz/uzZ/AbDjccMFFQFifcfZzvjxDeDOhAoWoWC6nA9GgFXCGYgAu9vigCei+Lv12oO/ZAKm3E87RYDjEAHW33ifImJ3p7ULiDBuuOOWlYUIILZvfKySDpwLbLIpi7lJgRqFCPD8mY98EKEnGfw/FsAIEeIQdIiNDxpev6oKuHEzMHoDjTpDnP02dACxfu6RqvzuO4UwfDGcAOGWKgj9hKtFoaDhwV1lkq6MK1kREXqLFMF/QiKXctvdro5J9Lem2ttSVoe6OPix/lbDxPV68qcv2o4nH+OngcNnkQmzWpJtaVGbTz/K5G1Y74G5Y0rpT1wJmWaEEuXOlob3HHWhUL9m+ALCwznizsfCfLlw8pQnrCW/xs0YoX4HTTrhwHmEhSds9VZ9V0skECanpOTI9IClRXVkXtzwXGkuwgVYQ2mHcYty0KQaihz17UlPhJCeHQS4mkW4dBl5Tkqw/kjCiuDHcq+Z+fLrzDz35nvQApL1tRvJkYiIVuhh0YlE/TgCLp9Ofp4wO7IL9qELxwbLVem+XscxdcO3E0drWIBjAnPNCrl8r17JTC4gX8nQhu7C6U/upcs/Kp1D7mglV4+xPge6B4etH/MPHGldchRA+lqXRBjysvVlNH4xMUmJqxEeR0NyQcPmLU70J3PpKBJT9pBreCNyQfDLKbthEc5wu27n3phn4pfc5+ebEYcW4BPCVQJZpXGUCIYav57jplZqreTCSvnFIwj/AMgKWXk81STmAAAAAElFTkSuQmCC',
              links: [{
                text: this.$t('dashboard_RAMP.localization_value.value'),
                textTranslate: 'dashboard_RAMP',
                linkText: this.$t('dashboard_RAMP.localization_value.value'),
                linkTextTranslate: 'dashboard_RAMP',
                useFunction: this.toggleCreateRAMPPopup
              }]
            })
            break

          case DASHBOARD_DRAGGABLE.CREATE_REQUEST_FUNDS:
            this.draggableList.push({
              firstElement: true,
              linkPath: this.$store.getters.GET_PATHS.financeRequestFundsIndividual,
              firstElementText: this.$t('dashboard_calculateFunds.localization_value.value'),
              sort: draggableItem.sort,
              dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
            })
            break
          // case DASHBOARD_DRAGGABLE.HEARTY_FROZEN_TRANSACTIONS:
          //   if (!this.isUserHeartySeller) return
          //
          //   this.draggableList.push({
          //     ico: '/img/dashboard-group/invoices-icon.svg',
          //     bigText: draggableItem?.values?.count ? draggableItem.values.count : '0',
          //     linkText: this.$t('dashboard_heartysanFrozen.localization_value.value'),
          //     linkTextTranslate: 'dashboard_heartysanFrozen',
          //     linkPath: this.$store.getters.GET_PATHS.cusPayments,
          //     sort: draggableItem.sort,
          //     dashboardSummaryItemId: draggableItem.dashboard_summary_item_id,
          //   })
          //   break
        }
      })
    }).catch(error => this.createErrorLog(error))

    this.$store.dispatch('getDashboardData').then(response => {
      let data = this.getRespData(response)
      let withoutTrackingTransactions = data?.withdraw_without_tracking_transactions

      if (withoutTrackingTransactions && Object.keys(withoutTrackingTransactions).length > 0) {
        this.withoutTrackingTransactions = withoutTrackingTransactions
      }
      if (data?.totals) {
        this.totalInfo = data['totals']
      }
    }).catch(error => this.createErrorLog(error))

    this.$store.dispatch('getReconnectShopData').then(response => {
      this.reconnectShops = this.getRespPaginateData(response);
    })


    // if (this.loadUserRoleAndAuthorizedData) {
    //   this.setUserBalance()
    //   this.setUserPaymentListings()
    // }

    // if (!Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')) {
    //   let interval = setInterval(() => {
    //     if (Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')) {
    //       this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
    //       clearInterval(interval)
    //     }
    //   }, 50)
    // } else {
    //   this.list[1].bigText = '$' + this.$store.getters.getUserProfile.balance
    // }

    // console.log(this.$store.getters.GET_COMMON_AUTHORIZED);
  },

  methods: {

    // setUserBalance() {
    //   if (!this._.has(this.currentPermissions, this.PERMISSIONS.PAYMENT_HISTORY_GENERAL)) return
    //   let balanceBlock = {
    //     ico: '/img/dashboard-group/balance-icon.svg',
    //     bigText: '$' + this.userBalance,
    //     textFromLink: 'My Balance',
    //     textFromLinkHref: this.$store.getters.GET_PATHS.balanceHistory,
    //
    //     bigText2: '$' + this.$store.getters.GET_COMMON_AUTHORIZED?.user?.frozen_balance,
    //     textFromLink2: this.$t('dashboard_OnHold.localization_value.value'),
    //     textFromLinkHref2: this.$store.getters.GET_PATHS.financePayPalCases + `?page=1&count=25&status=${PAYPAL_CASES_STATUS_OPENED.value}`,
    //   }
    //
    //   if (Object.hasOwnProperty.call(this.$store.getters.getUserProfile, 'balance')) {
    //     balanceBlock.bigText = '$' + this.$store.getters.getUserProfile.balance
    //   }
    //
    //   this.list.splice(1, 0, balanceBlock)
    // },

    // setUserPaymentListings() {
    //   if (!this.getCurrentUserSetting?.use_payment_listing) return
    //   let listingBlock = {
    //     ico: '/img/dashboard-group/listings-icon.svg',
    //     // bigText: '40',
    //     links: [
    //       {
    //         text: 'Listings',
    //         linkText: 'Create New',
    //         linkPath: this.$store.getters.GET_PATHS.financeUkrTreasuresLinkCreate
    //       },
    //       {
    //         text: 'Etsy',
    //         linkText: 'Create Etsy',
    //         useFunction: this.getEtsyListing
    //       },
    //     ],
    //   }
    //
    //   this.list.splice(6, 0, listingBlock)
    // },

    changeThankYouPopupPopup(val) {
      this.thankYouPopupPopup = val
    },

    changeInfoCardsList(list) {
      let data = []

      if(!isArray(list)) return

      list.map((listItem, index) => {
        // if(listItem?.firstElement) return
        data.push({
          "dashboard_summary_item_id" : listItem.dashboardSummaryItemId,
          "sort" : index
        })
      })
      this.$store.dispatch('changeSortDashboardDraggableBlockData', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
      this.draggableList = list
    },

    getEtsyListing() {
      this.importEtsyListings = true
    },

    toggleCreateRAMPPopup() {
      this.createRAMPPopup = !this.createRAMPPopup
    },

    toggleCreatePayoneerCheckPopup() {
      this.createPayoneerCheckPopup = !this.createPayoneerCheckPopup
    },

    removeReconnectShop(item) {
      this.$store.dispatch('deleteReconnectShopData', item.id).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let indexRemove = this._.findIndex(this.reconnectShops, {id: item.id})
            this.reconnectShops.splice(indexRemove, 1)
            this.openNotify('success', 'common_notificationStatusChanged')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

  },
}
</script>

<style scoped lang="scss">
@import "../../../../../scss/mixins/mixins";
@import "../../../../../scss/colors";

.dashboard-col {

  @include for-1400 {
    //&:nth-child(1) {
    //  order: 1;
    //}
    //&:nth-child(2) {
    //  order: 3;
    //}
    //&:nth-child(3),
    //&:nth-child(4) {
    //  order: 2;
    //}
    //&:nth-child(5) {
    //  order: 4;
    //}
    //&:nth-child(6) {
    //  order: 6;
    //}

    .order-mobile-last {
      order: 10;
    }

  }
}

.dashboard-info-block {
  display: flex;

  &__inner {
    padding: 16px 24px;
    background: white;
    border-radius: 5px;
  }

  &__row {
    width: 100%;
    display: flex;
  }

  &__img {
    max-width: 50px;
    min-width: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    width: 100%;
    margin-left: 10px;


    p {
      margin-bottom: 0;
      margin-top: 0;
      color: $black;
      font-size: 12px;
      line-height: 21px;
      font-weight: 500;
    }
  }

  &__title {
    color: $black;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 21px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

</style>
